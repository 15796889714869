html,
body,
canvas,
#pageContainer,
#page,
#canvasContainer,
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 5px;
  font-weight: normal;
}

div {
  text-align: center;
}

.btn {
  background: transparent;
  padding: 15px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  opacity: 1;
  transition: opacity 300ms ease;
}

.touched {
  opacity: 0.5;
}

#page {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

#pageContainer {
  position: relative;
}
#page {
  position: absolute;
  top: 0;
  left: 0;
}
#canvasContainer {
  position: relative;
  overflow: hidden;
}
